import { getPreviewDatabase } from '@/lib/common/utils/getPreviewDatabase'

function getDatabaseName() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview') {
    const database = getPreviewDatabase(process.env.VERCEL_URL || '')
    if (database) {
      process.env.MYSQL_DATABASE = database
    }
  }
  return process.env.MYSQL_DATABASE
}

export namespace config {
  export const logging = getLogConfiguration()

  export const env = {
    IS_VERCEL: process.env.VERCEL === '1',
    EMAIL_FROM: 'notifications@burnerpage.com',
    EMAIL_SALES: 'sales@burnerpage.com',
    EMAIL_SUPPORT: 'support@burnerpage.com',
    BRAND: 'Ab3.ai',
    BRAND_SEO: 'AB3',
    BRAND_SHORT: 'AB3',
    PERMAHOST: 'https://burnerpage.com',
    SUPPORT_FORM_URL: 'https://burnerpage.com/contact',
    BRNR_URL: process.env.NEXT_PUBLIC_PERMAHOST_TINY ?? 'http://brnr.local:3000',
    ADMIN_URL: process.env.NEXT_PUBLIC_SERVER_ADMIN ?? 'http://admin.burner.local:3000',
    MARKETING_URL: process.env.NEXT_PUBLIC_SERVER_MARKETING ?? 'https://burnerpage.com',
    GOOGLE_ANALYTICSID:
      process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? 'UA-189011800-2' : 'UA-189011800-1',
    FACEBOOK_PIXELID: '131344475670691',
    AWS_S3_BUCKET: process.env.AWS_S3_BUCKET ?? '',
    AWS_S3_ACCESS_KEY: process.env.AWS_S3_ACCESSKEY ?? '',
    AWS_S3_SECRET_KEY: process.env.AWS_S3_SECRETKEY ?? '',
    MYSQL_HOST: process.env.MYSQL_HOST ?? '',
    MYSQL_DATABASE: getDatabaseName(),
    MYSQL_USER: process.env.MYSQL_USER ?? '',
    MYSQL_PASSWORD: process.env.MYSQL_PASSWORD ?? '',
    STRIPE_PUBLISHABLEKEY:
      process.env.NEXT_PUBLIC_NODE_ENV === 'development'
        ? 'pk_test_51HwytFCXEEZkmUJ2jrTJuGeCPycpULbbUbBmnVZ2BrnkGAMV8UwS53JYPPPDnflvnvNupNlNozTWnntq5xubHAFG00QWuN8ARg'
        : 'pk_live_51HwytFCXEEZkmUJ2HRfHnKa7knRo45Jr7UIUHNzPzmOQJJSXxQ4kBC2KBvGVR61qOMv6ZF4gNNevqgNORg3p53uh00ESv4EU0G',
    STRIPE_SECRETKEY:
      process.env.NEXT_PUBLIC_NODE_ENV === 'production'
        ? process.env.STRIPE_SECRETKEY_LIVE ?? ''
        : process.env.STRIPE_SECRETKEY_TEST ?? '',
    STRIPE_WEBHOOK_SECRET:
      process.env.NEXT_PUBLIC_NODE_ENV === 'production'
        ? process.env.STRIPE_WEBHOOK_SECRET ?? ''
        : process.env.STRIPE_WEBHOOK_SECRET ?? '',
    UNSPLASH_ACCESS_KEY: process.env.UNSPLASH_ACCESS_KEY ?? '',
    APIFLASH_ACCESSKEY: '85047c91a2b54d23a5b4cd6f16acd66f',
    THUMB_OVERRIDE: 'aerbqweVas_fnwe13dfsdf',
    DOMAIN_API:
      process.env.NEXT_PUBLIC_NODE_ENV === 'development'
        ? 'https://dev.cname.brnr.cc/api/domain?domain='
        : 'https://cname.brnr.cc/api/domain?domain=',
    AI_API_URL: process.env.AI_API_URL,
    AI_API_KEY: process.env.AI_API_KEY,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY ?? '',
    LOGTAIL_TOKEN: process.env.NEXT_PUBLIC_LOGTAIL_TOKEN,
    VERCEL_APIKEY: process.env.VERCEL_APIKEY,
    NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV ?? '',
    GOOGLE_ID: process.env.GOOGLE_ID ?? '',
    GOOGLE_SECRET: process.env.GOOGLE_SECRET ?? '',
    JWT_KEY: process.env.JWT_KEY ?? '',
    GENERAL_SECRET: process.env.GENERAL_SECRET ?? '',
    S3_CDN_URL: process.env.S3_CDN_URL ?? '',
    SESSION_DURATION: process.env.SESSION_DURATION
      ? parseInt(process.env.SESSION_DURATION)
      : 24 * 60 * 60,
    USERSNAP_GLOBAL_API_KEY: process.env.NEXT_PUBLIC_USERSNAP_GLOBAL_API_KEY,
    ENVIRONMENT: (process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'local') as
      | 'local'
      | 'dev'
      | 'staging'
      | 'live',
    APP_SYNC_ENDPOINT: process.env.NEXT_PUBLIC_APP_SYNC_ENDPOINT ?? '',
    APP_SYNC_API_KEY: process.env.NEXT_PUBLIC_APP_SYNC_API_KEY ?? '',
    PREVIEW_URL: process.env.NEXT_PUBLIC_PREVIEW_URL ?? '',
    CAMPAIGN_DOMAIN: process.env.NEXT_PUBLIC_CAMPAIGN_DOMAIN ?? '',
    SLACK_WEBHOOK_URL_NEWUSER: process.env.SLACK_WEBHOOK_URL_NEWUSER ?? '',
    SLACK_WEBHOOK_URL_BROKEN_URLS: process.env.SLACK_WEBHOOK_URL_BROKEN_URLS ?? ''
  }

  export const settings = {
    MAX_DRAFT_VARIANTS: 5,
    MAX_IMAGE_SIZE_MB: 4,
    MAX_GOAL_COUNT: 5
  }

  export const supportMessages = {
    loadingAlert: 'Data is still loading...',
    missingInfo: `Our apologies: We are missing some information here. Please email us at ${env.EMAIL_SUPPORT} to let us know so we can fix it.`
  }

  export const general = {
    itemsPerPage: 30, //how many items to show per page
    pageBlocks: 5, //controls number of prev/next page numbers. should be an odd #
    saltRounds: 10 //used for bcrypt password encryption salt generation
  }

  export const billing = {
    freeTrial: {
      trialDays: 30,
      priceId:
        process.env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? 'price_1Orn9VCXEEZkmUJ2wxLH9N3S'
          : 'price_1OrnJkCXEEZkmUJ279lXoF0z'
    },
    starterPlan: {
      monthly: {
        priceId:
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'price_1Oiz5aCXEEZkmUJ2kbgqYDXA'
            : 'price_1OeS3oCXEEZkmUJ2zgf07lhV',
        cost: 299.0
      },
      yearly: {
        priceId:
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'price_1Oiz5lCXEEZkmUJ2VFxtB5LR'
            : 'price_1OiyxDCXEEZkmUJ221FKdOF9',
        cost: 249.0
      },
      name: 'Starter',
      productId:
        process.env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? 'prod_PTOlBdXTelCsfB'
          : 'prod_PTOrCDtag7whJK'
    },
    standardPlan: {
      monthly: {
        priceId:
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'price_1OeRxuCXEEZkmUJ26MoIoWHp'
            : 'price_1OeS3dCXEEZkmUJ2I84HiqyI',
        cost: 599.0
      },
      yearly: {
        priceId:
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'price_1Oiz50CXEEZkmUJ2S4nQW0Gq'
            : 'price_1Oiyy1CXEEZkmUJ2gQpjkXTn',
        cost: 499.0
      },
      name: 'Standard',
      productId:
        process.env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? 'prod_PTOlXw4GPOrxKj'
          : 'prod_PTOr6sJYAbXkf1'
    },
    businessPlan: {
      monthly: {
        priceId:
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'price_1OeRy6CXEEZkmUJ245SXU5sU'
            : 'price_1Oiz0FCXEEZkmUJ2xTgb8sbY',
        cost: 1199.0
      },
      yearly: {
        priceId:
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'price_1Oiz4MCXEEZkmUJ2FX2xHiNz'
            : 'price_1Oiz0UCXEEZkmUJ2dX9lFkJm',
        cost: 999.0
      },
      name: 'Business',
      productId:
        process.env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? 'prod_PTOlIEJ6lqUvEv'
          : 'prod_PQlcEO5X34qM0l'
    }
  }

  export const premium = {
    planNames: {
      1: `${env.BRAND_SHORT} Free Plan`,
      2: `${env.BRAND_SHORT} Trailblazer Plan`
    } as { [key: string]: string }
  }

  export const message: Record<string, string[]> = {
    success: [
      'Mission accomplished!',
      'Nicely done!',
      'Living the dream!',
      'To infinity and beyond!',
      'Great work!',
      'Amazing!',
      'Go you!',
      'I knew you could do it!',
      "You're a rockstar!",
      "You're a wizard, Harry!"
    ],
    failure: ['Uh oh!', 'Looks like something went wrong :(', 'Well, this is embarassing'],
    loading: [
      'Loading page',
      'Gathering driftwood',
      'Stoking the ashes',
      'Pouring gasoline',
      'Pumping the bellows',
      'Sparking the kindling',
      'Stirring the coals',
      'Fanning the flames',
      'Incinerating the evidence',
      'Digging the firepit',
      'Spraying lighter fluid',
      'Lighting the match',
      'Blazing up',
      'Applying friction',
      'Brushing off the ashes'
    ]
  }

  export const email = {
    signatureShort: `Best,
Avi Muchnick
CEO`,
    signature: `Best,
Avi Muchnick
CEO

PS. Here’s my personal number. 516-537-8733 
Please reach out at any time or schedule a video call with me here: https://calendly.com/burnerpage/15min`,
    footer: `
Best,\n
Avi and the ${config.env.BRAND} team`
  }

  type INav = Record<
    string,
    {
      title: string
      titles: string
      url: string
    }
  >

  export const nav: INav = {
    team: {
      title: 'Team',
      titles: 'Team',
      url: '/settings/team'
    },
    members: {
      title: 'Team member',
      titles: 'Team members',
      url: '/settings/team/members'
    },
    info: {
      title: 'User Info',
      titles: 'User Info',
      url: '/settings/user/info'
    },
    preferences: {
      title: 'Preferences',
      titles: 'Preferences',
      url: '/settings/user/preferences'
    },
    billing: {
      title: 'Billing',
      titles: 'Billing',
      url: '/settings/team/billing'
    },
    username: {
      title: 'Username',
      titles: 'Username',
      url: '/settings/user/username'
    },
    password: {
      title: 'Password',
      titles: 'Password',
      url: '/settings/user/password'
    },
    defaultTeam: {
      title: 'Default Team',
      titles: 'Default Team',
      url: '/settings/user/default_team'
    }
  }

  export const campaign = {
    experimentViewLimit: 1000,
    experimentVariantCount: 5,
    experimentTemplateId: 2,
    nameMaxLength: 255,
    descriptionMaxLength: 2000,
    maxTrafficAllocation: 90
  }

  export const stats = {
    notEnoughDataDialogViewsThreshold: 100,
    imageVariantCachedWidth: 600,
    imageVariantCachedHeight: 336,
    demoDataEndDate: new Date(1999, 11, 31),
    conversionWindow: 7 * 24 * 60 * 60 * 1000
  }

  export const meta = {
    image: `${env.MARKETING_URL}/images/marketing/page-card-array.png`,
    title: `${env.BRAND} - AI Page Optimization`,
    url: `${env.MARKETING_URL}`,
    description:
      'Watch page performance skyrocket with our automated platform. Our AI generates page variants automatically and runs continuous experiments while your team sleeps, to constantly tweak and improve performance.',
    type: 'website'
  }
}

function getLogConfiguration() {
  const value = process.env.NEXT_PUBLIC_LOG ?? ''
  if (value.startsWith('whitelist:')) {
    return {
      type: 'whitelist',
      categories: getLogCategories(value.substring('whitelist:'.length))
    }
  } else if (value.startsWith('blacklist:')) {
    return {
      type: 'blacklist',
      categories: getLogCategories(value.substring('blacklist:'.length))
    }
  } else {
    return {
      type: 'whitelist',
      categories: getLogCategories(value)
    }
  }
}

function getLogCategories(value: string) {
  value = value.trim()
  if (!value) {
    return []
  }
  return value.split(',').map(value => value.trim())
}
